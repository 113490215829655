#courses-page-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  height: 100%;
  padding: 7rem 3% 2rem 5%;
}
.courses-page-header {
  margin-bottom: 2rem;
}
