.initials-container {
  width: clamp(50px, 50px + 1vw, 100px);
  height: clamp(50px, 50px + 1vw, 100px);

  aspect-ratio: 1;
  display: grid !important;
  place-items: center !important;
  color: var(--very-light-white);
  background: var(--primary-color);
  font-weight: 400;
  font-size: clamp(0.8rem, 1rem + 1vw, 2rem);
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  position: relative;
}

.small-drawer-avatar {
  width: 20%;
  background: #000;
  max-width: 128px;
}

.small-drawer-avatar {
  width: clamp(35px, 35px + 1vw, 70px) !important;
  height: clamp(35px, 35px + 1vw, 70px) !important;
  font-size: 1.2rem !important;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.65);
  aspect-ratio: 1;
}

.small-appbar-avatar {
  width: 40px;
  height: 40px;
  font-size: 1.2rem !important;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.65);
  aspect-ratio: 1;
  padding: 1px;
  border: 1px solid rgba(0, 0, 0, 0.513);
}
.select-picture-avatar,
.course-img-avatar {
  cursor: pointer;
  width: clamp(80px, 80px + 1vw, 200px) !important;
  height: clamp(80px, 80px + 1vw, 200px) !important;
  font-size: 1.5rem !important;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.65);
  aspect-ratio: 1;
}
.settings-page-avatar {
  width: clamp(150px, 150px + 1vw, 200px);
  height: clamp(150px, 150px + 1vw, 200px);
  font-size: 1.5rem !important;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;

  margin-bottom: 2rem;
}
.small-staff-table-avatar {
  width: clamp(18px, 18px + 1vw, 40px);
  height: clamp(18px, 18px + 1vw, 40px);
  font-size: 1.2rem !important;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  aspect-ratio: 1;
}
.top-user-avatar {
  width: clamp(40px, 40px + 1vw, 45px);
  height: clamp(40px, 40px + 1vw, 45px);
  font-size: 1rem !important;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  aspect-ratio: 1;
}
.course-img-avatar {
  box-shadow: none;
  object-fit: contain;
}
.small-card-avatars {
  width: clamp(20px, 20px + 1vw, 30px);
  height: clamp(20px, 20px + 1vw, 30px);
  font-size: 1rem !important;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  aspect-ratio: 1;
  padding: 1px;
  border: 1px solid rgb(64, 106, 219);
}
.activities-avatar {
  width: clamp(40px, 40px + 1vw, 50px);
  height: clamp(40px, 40px + 1vw, 50px);
  font-size: 1rem !important;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  aspect-ratio: 1;
}
.profile-page-avatar {
  width: clamp(200px, 200px + 1vw, 220px);
  height: clamp(200px, 200px + 1vw, 250px);

  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  aspect-ratio: 1;
  display: grid !important;
  place-items: center !important;
  font-size: 4em !important;

  border: 5px solid rgb(255, 255, 255);
}
.course-avatar-profile-page {
  width: clamp(30px, 30px + 1vw, 120px);
  height: clamp(30px, 30px + 1vw, 120px);
  object-fit: contain;
}

.small-drawer-avatar-search {
  width: clamp(20px, 20px + 1vw, 40px) !important;
  height: clamp(20px, 20px + 1vw, 40px) !important;
  font-size: 1rem !important;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  aspect-ratio: 1;
}
.small-notifications-avatar {
  width: clamp(30px, 30px + 1vw, 50px) !important;
  height: clamp(30px, 30px + 1vw, 50px) !important;
  font-size: 1.2rem !important;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  aspect-ratio: 1;
}
