.login-error {
  font-size: 12px;
  font-weight: 400;
  color: var(--color-alert-error-text);
  background-image: linear-gradient(
    var(--color-alert-error-bg),
    var(--color-alert-error-bg)
  );
  border: 1px solid var(--color-alert-error-border);
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  padding: 15px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}
.login-error span {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-right: 1.5rem;
}
.login-error aside {
  max-width: 12px;
  cursor: pointer;
}
