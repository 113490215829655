/* Dropdown Menu Zone */

/* Icon Button */
.icon-button {
  --button-size: calc(var(--nav-size) * 0.6);
  width: var(--button-size);
  height: var(--button-size);
  background-color: #e7e7e7;
  border-radius: 50%;
  padding: 8px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.icon-button:hover {
  filter: brightness(1.2);
}

.icon-button svg {
  fill: var(--text-color);
  width: 40px;
  height: 40px;
}

/* Dropdown Menu */

.dropdown {
  position: absolute;
  top: 40px;
  left: -300px;
  width: 350px;
  z-index: 2;
  transform: translateX(0%);
  border-radius: var(--border-radius);
  padding: 24px;
  overflow: hidden;
  transition: height var(--speed) ease;
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  min-height: 190px;
  background: #fff;
  border: 1px solid rgba(227, 227, 227, 0.62);
  box-shadow: 6px 6px 10px rgba(177, 174, 174, 0.17);
  border-radius: 16px;
  -webkit-transition: height var(--speed) ease;
  -moz-transition: height var(--speed) ease;
  -ms-transition: height var(--speed) ease;
  -o-transition: height var(--speed) ease;
}

.menu {
  width: 100%;
}

.menu-item {
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  transition: background var(--speed);
  padding: 0.5rem 0.5rem 0.5rem 0rem;
  color: var(--third-color);
  font-weight: 500;
  margin: 0rem 0rem 0.8rem 0rem;
  align-items: center;
  -webkit-transition: background var(--speed);
  -moz-transition: background var(--speed);
  -ms-transition: background var(--speed);
  -o-transition: background var(--speed);
}

.menu-item .icon-button {
  margin-right: 0.5rem;
}

.menu-item .icon-button:hover {
  filter: none;
}

.menu-item:hover {
  background-color: #68696d28;
}

.icon-right {
  margin-left: auto;
}
/* CSSTransition classes */
.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}
.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-primary-exit {
  position: absolute;
}
.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all var(--speed) ease;
}

/* SECONDARY MENU */
.menu-secondary-enter {
  transform: translateX(110%);
}
.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-secondary-exit {
}
.menu-secondary-exit-active {
  transform: translateX(-110%);
  transition: all var(--speed) ease;
  -webkit-transform: translateX(-110%);
  -moz-transform: translateX(-110%);
  -ms-transform: translateX(-110%);
  -o-transform: translateX(-110%);
}

/* THIRD MENU */

.menu-third-enter {
  position: absolute;
  top: 24px;
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
}
.menu-third-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
}
.menu-third-exit {
}
.menu-third-exit-active {
  transform: translateX(110%);
  transition: all var(--speed) ease;
  -webkit-transform: translateX(110%);
  -moz-transform: translateX(110%);
  -ms-transform: translateX(110%);
  -o-transform: translateX(110%);
}

/* Report Type Section */
.select-report-type-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.select-report-type-wrapper span {
  line-height: min-content;
}
.select-report-type-wrapper p {
  font-size: 0.8rem;
  margin: 0;
}
.report-option-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0rem;
  gap: 1rem;
}
.report-option-space-between input {
  width: 100%;
}
.report-option-space-between label {
  color: var(--third-color);
  font-weight: 500;
}
#demo-mutiple-name-label {
  transform: translate(0);
  -webkit-transform: translate(0);
  -moz-transform: translate(0);
  -ms-transform: translate(0);
  -o-transform: translate(0);
}

.report-option-menu {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.extract-report-button {
  width: 100%;
  background: #0e4da4;
  border-radius: 6px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 0.5rem 0rem;
  margin: 1rem 0rem;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}
.extract-report-button span {
  color: var(--very-light-white);
  font-weight: 600;
}
.icons8-share-rounded-white {
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMTYiIGhlaWdodD0iMTYiCnZpZXdCb3g9IjAgMCAxNzIgMTcyIgpzdHlsZT0iIGZpbGw6IzAwMDAwMDsiPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0ibm9uemVybyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIHN0cm9rZS1saW5lY2FwPSJidXR0IiBzdHJva2UtbGluZWpvaW49Im1pdGVyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1kYXNoYXJyYXk9IiIgc3Ryb2tlLWRhc2hvZmZzZXQ9IjAiIGZvbnQtZmFtaWx5PSJub25lIiBmb250LXdlaWdodD0ibm9uZSIgZm9udC1zaXplPSJub25lIiB0ZXh0LWFuY2hvcj0ibm9uZSIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOiBub3JtYWwiPjxwYXRoIGQ9Ik0wLDE3MnYtMTcyaDE3MnYxNzJ6IiBmaWxsPSJub25lIj48L3BhdGg+PGcgZmlsbD0iI2ZmZmZmZiI+PHBhdGggZD0iTTg2LDE0LjMzMzMzbC0yOC42NjY2NywyOC42NjY2N2gyMS41djg2aDE0LjMzMzMzdi04NmgyMS41ek00Myw1MC4xNjY2N2MtNy44MzM2MiwwIC0xNC4zMzMzMyw2LjQ5OTcyIC0xNC4zMzMzMywxNC4zMzMzM3Y3OC44MzMzM2MwLDcuODMzNjIgNi40OTk3MiwxNC4zMzMzMyAxNC4zMzMzMywxNC4zMzMzM2g4NmM3LjgzMzYyLDAgMTQuMzMzMzMsLTYuNDk5NzIgMTQuMzMzMzMsLTE0LjMzMzMzdi03OC44MzMzM2MwLC03LjgzMzYyIC02LjQ5OTcyLC0xNC4zMzMzMyAtMTQuMzMzMzMsLTE0LjMzMzMzaC03LjE2NjY3djE0LjMzMzMzaDcuMTY2Njd2NzguODMzMzNoLTg2di03OC44MzMzM2g3LjE2NjY3di0xNC4zMzMzM3oiPjwvcGF0aD48L2c+PC9nPjwvc3ZnPg==')
    50% 50% no-repeat;
  background-size: 100%;
}
.extract-report-button:hover {
  filter: brightness(105%);
  -webkit-filter: brightness(105%);
}
.report-name-input-field {
  padding: 2px 8px;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  min-height: 38px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.report-name-input-field:target {
  border-color: #2684ff;
}
.options-container {
  display: flex;
  font-size: 0.8rem;
  flex-wrap: wrap;
  color: var(--third-color);
  opacity: 0.75;
  justify-content: space-between;
}

.manual-assign-form {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
}

.assign-input-field-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  gap: 1rem;
  margin-bottom: 1rem;
}
.assign-input-field-container input {
  min-height: 38px;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 2px 8px;
  width: 100%;
  color: var(--primary-color);
}
.input-field-error {
  border-color: var(--color-danger-area-text) !important;
  border-width: 1.5px !important;
  color: var(--color-danger-area-text) !important;
}
.identifier-error {
  position: absolute;
  bottom: -20px;
  font-size: 0.8rem;
  color: var(--color-danger-area-text);
  font-weight: 400;
}
.identifier-error-enter {
  opacity: 0;
}
.identifier-error-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.identifier-error-exit {
  opacity: 1;
}
.identifier-error-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}
.save-changes-button {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  background: #0e4da4;
  border-radius: 6px;
  color: #fff !important;
  font-weight: 500;
  gap: 6px;
  padding: 0.5rem 0rem;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}
.identifiers-list {
  padding: 4px 0px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 6px;
  width: 100%;
}
.identifiers-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (min-width: 720px) {
  .dropdown {
    left: -200px;
    width: 420px;
  }
}
/* Desktop */
