#assign-lecturers-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  height: 100%;
  padding: 7rem 3% 2rem 5%;
}
#assign-lecturers-page header p {
  font-weight: 500;
}
#assign-lecturers-page header h4 {
  font-size: clamp(1rem, 1rem + 1vw, 1.5rem);
  font-weight: 500;
  position: relative;
}
.courses-card-grid-container {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}

.courses-card-grid-container li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  background: #ffffff;
  /* Card Stroke */

  border: 0.5px solid rgba(51, 77, 110, 0.24);
  /* Design Shadow */

  box-shadow: 6px 6px 18px -10px rgba(75, 74, 74, 0.15);
  padding: 1rem;
  transition: transform 0.5s;
  -webkit-transition: transform 0.5s;
  -moz-transition: transform 0.5s;
  -ms-transition: transform 0.5s;
  -o-transition: transform 0.5s;
  cursor: pointer;
}
.courses-card-grid-container li:hover {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
}
.single-course-card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: 100%;
}
.single-course-card-img {
  max-width: 60px;
  width: 100%;
  aspect-ratio: 1;
}
.single-course-card-img img {
  width: 100%;
}

.single-course-card-container header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 5px;
  margin-top: 5px;
}
.single-course-card-container header h6 {
  font-weight: 500;
  font-size: clamp(0.5rem, 0.5rem + 1vw, 1rem);
  color: var(--primary-color);
}

.single-course-card-container header span {
  font-weight: 500;
  font-size: clamp(0.3rem, 0.3rem + 1vw, 0.8rem);

  color: var(--secondary-color);
  opacity: 0.8;
}

@media only screen and (min-width: 720px) {
}
/* Desktop */

@media only screen and (min-width: 1024px) {
  .courses-card-grid-container {
    display: grid;
    grid-gap: 2rem;

    grid-template-columns: repeat(5, minmax(calc(100% / 6), 1fr));
  }
}
