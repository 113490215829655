#bad-gateway-page {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  gap: 2rem;
  background: white;
}
.bad-gateway-img {
  width: 240px;
}
.bad-gateway-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bad-gateway-header p {
  max-width: 500px;
  text-align: center;
  font-size: 0.875rem;
  color: #333;
}
.bad-gateway-header span {
  font-size: 12px;
  color: #bbb;
  font-weight: 400;
}
