.small-logo {
  display: inline;
  max-width: 20%;
}
.small-logo img {
  object-fit: contain;
  width: clamp(70px, 70px + 1vw, 90px);
  display: inline-block;
}
.login-logo {
  display: block;
  max-width: 112px;
  cursor: pointer;
}
.drawer-logo {
  position: relative;
  top: 24px;
  left: 24px;
  z-index: -1;
  max-width: 75px;
  display: flex;
  align-items: center;
}
.drawer-logo img {
  width: 100%;
  object-fit: contain;
}
