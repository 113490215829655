:root {
  --color-alert-error-border: rgba(158, 28, 35, 0.2);
  --color-alert-error-text: #24292e;
  --color-alert-error-icon: rgba(158, 28, 35, 0.6);
  --color-danger-area-text: #eb5d63;
  --color-danger-area-icon-background: #eb5d6442;

  --color-link-text: #109cf1;
  --color-alert-error-bg: #ffe3e6;
  --third-color: #334d6e;
  --primary-color: #192a3e;
  --secondary-color: #6c7c90;
  --fourth-color: #8f9eb8;
  --main-compare-green-color: #31c977;
  --main-compare-red-color: #e47c67;

  --card-background-blue-color: #109bf13d;
  --button-bg-color: #0a66c2;

  --card-border-shadow: 5px 6px 18px rgba(0, 0, 0, 0.29);
  --arabic-font-family: Arial, Helvetica, sans-serif;
  --main-font-family: 'Poppins', sans-serif;
  --secondary-font-family: system-ui, 'Helvetica', 'Arial', sans-serif;

  --transparent-white: hsla(0, 0%, 100%, 0.75);
  --very-light-white: hsl(0, 0%, 95%);

  /* Transitions Elements */
  --bg: hsl(0, 0%, 95%);
  --bg-accent: #484a4d;
  --text-color: #dadce1;
  --nav-size: 60px;
  --border: 1px solid #474a4d44;
  --border-radius: 8px;
  --speed: 500ms;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--main-font-family);
}

html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}
body {
  height: 100vh;
  font-family: var(--main-font-family);
  letter-spacing: 0.01rem;
  overflow-x: hidden;
  background: #f5f6f8 !important;
}

h1 {
  font-size: clamp(2.5rem, 2.5rem + 0.5vw, 4rem);
}
h2 {
  font-size: clamp(2rem, 2rem + 0.5vw, 3.5rem);
}
h3 {
  font-size: clamp(1.5rem, 1.5rem + 0.5vw, 3rem);
}
h4 {
  font-size: clamp(1.25rem, 1.25rem + 0.5vw, 2rem);
}
h5 {
  font-size: clamp(1rem, 1rem + 0.5vw, 1.5rem);
}
h6 {
  font-size: clamp(0.8rem, 0.8rem + 0.5vw, 1.2rem);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--primary-color);
}
h1,
h2,
h3 {
  font-weight: 600;
}
h4,
h5,
h6 {
  font-weight: 500;
}

.dark-font-color {
  color: var(--third-color);
}
.font-weight400 {
  font-weight: 400;
}
.font-weight500 {
  font-weight: 500;
}
.font-weight600 {
  font-weight: 600;
}
p {
  margin: 12px 0px;
  color: var(--secondary-color);
  font-size: clamp(0.7rem, 0.7rem + 0.5vmin, 1.5rem);
  font-weight: 400;
  -webkit-text-size-adjust: 100%;
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

p a,
p span {
  color: var(--color-link-text);
  cursor: pointer;
}

a {
  text-decoration: none;
}
ul {
  list-style-type: none;
}

button {
  outline: none;
  cursor: pointer;
  background: transparent;
  border: 0;
}
button:hover {
  filter: brightness(110%);
  -webkit-filter: brightness(110%);
}
button:disabled {
  opacity: 0.7;
  cursor: default;
  filter: none;
  -webkit-filter: none;
  box-shadow: none;
}
button:active {
  color: inherit;
  filter: brightness(98%);
  -webkit-filter: brightness(98%);
}
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.btn-grad {
  font-family: var(--main-font-family);
  font-weight: 500;
  border: none;
  margin: 40px 0px 20px 0px;
  padding: 10px 32px;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 8px;
  display: block;
  background: var(--primary-color);
  cursor: pointer;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  outline: none;
  position: relative;
  transition: padding-right 0.3s ease-out;
  font-size: clamp(0.8rem, 0.5rem + 0.4vw, 1.2rem);
}

.cursor-pointer {
  cursor: pointer;
}

.error-color-icon {
  color: var(--color-alert-error-icon);
}
.green-stock {
  color: var(--main-compare-green-color);
}
.red-stock {
  color: var(--main-compare-red-color);
}
.card-shadow {
  border: 1px solid rgba(51, 77, 110, 0.24);
  background: #fff;
  box-shadow: var(--card-border-shadow);
}
.drawer-avatar-container {
  display: flex;
  height: 160px;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  padding-right: 6px;
}
.user-information {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: var(--main-font-family);
  font-weight: 500;
  width: 100%;
  color: #192a3e;
  margin-left: 14px;
}
.user-information span {
  width: 100%;
  white-space: break-word;
  overflow: hidden;
  text-overflow: break-inside;
  font-size: 0.8rem;
}
.user-information p {
  margin: 0;
  font-weight: 500;
  font-size: 0.75rem;
}

.avatar {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.center-spinner {
  outline: none;
  display: grid;
  position: fixed;
  place-items: center;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
.svg-img {
  display: block;
  width: 100%;
  height: auto;
  aspect-ratio: 1;
  min-width: calc(300px - 20px);
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  color: #1a73e8 !important;
  border-color: #1a73e8 !important;
  font-size: 14px !important;
}
.MuiOutlinedInput-root.Mui-focused,
.MuiOutlinedInput-root,
.MuiOutlinedInput-root {
  color: #333 !important;
  font-size: 14px !important;
}

.MuiInputLabel-outlined.Mui-focused {
  color: #1a73e8 !important;
}
.MuiAlert-message {
  font-family: var(--secondary-font-family);
}
a {
  color: inherit;
}
.primary-color {
  color: var(--primary-color);
}
.secondary-color {
  color: var(--secondary-color);
}
.third-color {
  color: var(--third-color);
}
.fourth-color {
  color: var(--fourth-color);
}

@keyframes spin {
  to {
    transform: rotate(359deg);
  }
}
@keyframes grow {
  to {
    width: 14px;
    height: 14px;
    margin-top: -8px;
    right: 13px;
  }
}

.rdt_TableCell > * {
  font-family: var(--secondary-font-family);
}
.extract-report-form .MuiFormControl-root label {
  z-index: 100;
  background: rgb(255, 255, 255);
}

.one-rem-fon-size {
  font-size: 1rem;
}
