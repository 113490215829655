#settings-page-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  position: relative;
  padding: 20% 3% 2rem 3%;
  background: #f5f6f8;
}
#settings-page-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  gap: 3rem;
}
.settings-page-header {
  grid-column: 1/2;
  grid-row: 1/2;
}
#settings-page-container ul {
  grid-column: 1/2;
  min-height: 400px;
}
#settings-page-container ul {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  font-weight: 500;
  color: var(--secondary-color);
  border-right: 1px solid rgba(98, 98, 98, 0.342);
  padding-right: 2rem;
  -webkit-border-right: 1px solid #000;
  -moz-border-right: 1px solid #000;
  -ms-border-right: 1px solid #000;
  -o-border-right: 1px solid #000;
  height: 100%;
  position: relative;
  gap: 0.5rem;
}
#settings-page-container ul li {
  padding: 0.6rem 0.7rem;
  white-space: nowrap;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  cursor: pointer;
}
.selected-tap {
  color: var(--primary-color);
  font-weight: 600;
  background: var(--card-background-blue-color);
}
.settings-section {
  display: grid;
  grid-template-rows: 0.12fr 1fr;
  grid-template-columns: 1fr;
}
.edit-profile-form {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
  justify-content: space-around;
}
.save-changes-btn {
  padding: 0.8rem 1rem;
  width: fit-content;
  max-width: 180px;
  background: #0c75ff;
  border-radius: 6px;
  color: #fff;
  font-weight: 600;
  margin-top: 50px;
}
.preferences-list-container {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  margin-top: 4rem;
}
.single-preference {
  display: flex;
  align-items: center;
  width: 90%;
  justify-content: space-between;
}
.title-with-subtitle-pref {
  width: 40ch;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.title-with-subtitle-pref h6 {
  font-weight: 500;
}
.security-section {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 15rem;
  position: relative;
}
.change-password-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1rem;
  position: absolute;
  width: 100%;
  height: 100%;
}
.change-password-container {
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
}

.change-password-form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  grid-column: 2/3;
  grid-row: 2/3;
}
.change-password-form input {
  padding: 0.5rem;
  background: #f5f6f8;
  border: 1px solid #dfdfe8;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  max-width: 300px;
}

.show-change-password-form-enter {
  opacity: 0;
}
.show-change-password-form-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.show-change-password-form-exit {
  opacity: 1;
}
.show-change-password-form-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

.identifier-error {
  position: absolute;
  top: 15px;
  font-size: 0.8rem;
  color: var(--color-danger-area-text);
  font-weight: 500;
  z-index: -1;
}
.identifier-error-enter {
  opacity: 0;
}
.identifier-error-enter-active {
  opacity: 1;
  transition: opacity 200ms;
  -webkit-transition: opacity 200ms;
  -moz-transition: opacity 200ms;
  -ms-transition: opacity 200ms;
  -o-transition: opacity 200ms;
}
.identifier-error-exit {
  opacity: 1;
}
.identifier-error-exit-active {
  opacity: 0;
  transition: opacity 200ms;
  -webkit-transition: opacity 200ms;
  -moz-transition: opacity 200ms;
  -ms-transition: opacity 200ms;
  -o-transition: opacity 200ms;
}

.changed-password-success {
  color: #5fb933;

  position: absolute;
  top: 15px;
  font-size: 0.8rem;
  font-weight: 500;
  height: fit-content;
}
.change-password-form .identifier-error {
  z-index: auto;
  height: fit-content;
}
@media only screen and (min-width: 1024px) {
  #settings-page-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    position: relative;
    padding: 10% 3% 2rem 16%;
  }
  #settings-page-container {
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-template-rows: 0.2fr auto;
    gap: 3rem;
  }
}
