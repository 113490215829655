#registration-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem 1rem;
  background: rgba(248, 248, 248, 0.657);
  height: 100vh;
  content-visibility: auto;
}

.register-page-container {
  background: #fff;
  width: 500px;
  padding: 2rem 2rem 0.6rem 2rem;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  margin-top: 0.5rem;
  position: relative;
  overflow: hidden;
  transition: transform 0.5s ease-in;
  -webkit-transition: transform 0.5s ease-in;
  -moz-transition: transform 0.5s ease-in;
  -ms-transition: transform 0.5s ease-in;
  -o-transition: transform 0.5s ease-in;
}

.create-account-title {
  font-size: 24px;
  font-weight: 300;
  letter-spacing: -0.5px;
}
.pre-form-information {
  color: var(--primary-color);
  font-weight: 400;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}
.signup-form {
  display: flex;
  flex-direction: column;
  margin: 2rem 0rem;
}
.signup-form > div {
  margin-bottom: 1rem;
}
.row-fields {
  display: flex;
  gap: 1rem;
}
.row-fields > div {
  width: 100%;
}
.register-submit-btn {
  margin-top: 1rem;
  display: flex;
  background: #4895fe;
  align-items: center;
  padding: 0.5rem 2rem;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  box-shadow: 0px 15px 20px -13px #4894feaf;
}
.register-submit-btn span:nth-child(1) {
  flex: 1;
  font-size: 1rem;
  font-weight: 500;
  color: var(--very-light-white);
}

.icons8-right {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNTAiIGhlaWdodD0iNTAiCnZpZXdCb3g9IjAgMCAxNzIgMTcyIgpzdHlsZT0iIGZpbGw6IzAwMDAwMDsiPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0ibm9uemVybyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIHN0cm9rZS1saW5lY2FwPSJidXR0IiBzdHJva2UtbGluZWpvaW49Im1pdGVyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1kYXNoYXJyYXk9IiIgc3Ryb2tlLWRhc2hvZmZzZXQ9IjAiIGZvbnQtZmFtaWx5PSJub25lIiBmb250LXdlaWdodD0ibm9uZSIgZm9udC1zaXplPSJub25lIiB0ZXh0LWFuY2hvcj0ibm9uZSIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOiBub3JtYWwiPjxwYXRoIGQ9Ik0wLDE3MnYtMTcyaDE3MnYxNzJ6IiBmaWxsPSJub25lIj48L3BhdGg+PGcgZmlsbD0iI2ZmZmZmZiI+PHBhdGggZD0iTTEwMi42NjI1LDQ1LjA0MjVjLTIuNjc0MDYsMC4yNTUzMSAtNC45NTg0NCwyLjA1NTk0IC01LjgzMTg3LDQuNTk1NjNjLTAuODg2ODgsMi41NTMxMiAtMC4yMDE1Niw1LjM3NSAxLjc0Njg3LDcuMjI5MzdsMjIuMjUyNSwyMi4yNTI1aC04Ni40M2MtMC4yMTUsLTAuMDEzNDQgLTAuNDMsLTAuMDEzNDQgLTAuNjQ1LDBjLTMuODAyODEsMC4xNzQ2OSAtNi43MzIxOSwzLjM5OTY5IC02LjU1NzUsNy4yMDI1YzAuMTc0NjksMy44MDI4MSAzLjM5OTY5LDYuNzMyMTkgNy4yMDI1LDYuNTU3NWg4Ni40M2wtMjIuMzYsMjIuMjUyNWMtMi43MDA5NCwyLjcwMDk0IC0yLjcwMDk0LDcuMDgxNTYgMCw5Ljc4MjVjMi43MDA5NCwyLjcwMDk0IDcuMDgxNTYsMi43MDA5NCA5Ljc4MjUsMGwzMy45NywtMzQuMDc3NWw0Ljk0NSwtNC44Mzc1bC00Ljk0NSwtNC44Mzc1bC0zMy45NywtMzQuMDc3NWMtMS40NTEyNSwtMS40OTE1NiAtMy41MDcxOSwtMi4yNDQwNiAtNS41OSwtMi4wNDI1eiI+PC9wYXRoPjwvZz48L2c+PC9zdmc+')
    50% 50% no-repeat;
  background-size: 100%;
}
.form-error-message {
  color: #f44336;
  font-size: 12px;
  margin-top: 0.5rem;
  font-family: var(--secondary-font-family);
}
.loading-bar {
  width: 100%;
  margin: 0;
  position: absolute !important;
  top: 0px;
  left: 0px;
  z-index: 101;
}

.loading-bar > * {
  background: #4895fe !important;
}
.disabled-form {
  opacity: 0.5;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: rgb(255, 255, 255);
  left: 0;
  z-index: 100;
}
.avatar-upload-step {
  width: 100%;
  height: 100%;
  padding: 2rem 2rem 0.6rem 2rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.5s ease-out;
  -webkit-transition: transform 0.5s ease-out;
  -moz-transition: transform 0.5s ease-out;
  -ms-transition: transform 0.5s ease-out;
  -o-transition: transform 0.5s ease-out;
  transform: translateX(200%);
  -webkit-transform: translateX(200%);
  -moz-transform: translateX(200%);
  -ms-transform: translateX(200%);
  -o-transform: translateX(200%);
  transition-delay: 0.15s;
}

.animation-begin-next-stage {
  transform: translateX(0%);
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
}

.first-stage-container {
  top: 0;
  left: 0;
  transition: transform 0.5s ease-out;
  -webkit-transition: transform 0.5s ease-out;
  -moz-transition: transform 0.5s ease-out;
  -ms-transition: transform 0.5s ease-out;
  -o-transition: transform 0.5s ease-out;
}
.animation-begin-previous-stage {
  transform: translateX(-120%);
  -webkit-transform: translateX(-120%);
  -moz-transform: translateX(-120%);
  -ms-transform: translateX(-120%);
  -o-transform: translateX(-120%);
  transition-delay: 0.15s;
}
.image-uploader-step {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 0.8fr 70% 1fr;
}
.image-uploader-step header {
  grid-row: 1/2;
}
.image-uploader-step:nth-child(2) {
  grid-row: 2/3;
}
.image-selector-container {
  grid-row: 3/4;
}
.image-uploader-step .upload-avatar-btns {
  grid-row: 4/5;
}
.upload-avatar-btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  margin: 1rem 0rem 1rem 0rem;
}
.upload-avatar-btns button:nth-of-type(2) {
  background: #000;
  color: var(--very-light-white);
  font-weight: 500;
  display: flex;
  background: #4895fe;
  align-items: center;
  padding: 0.5rem 2rem;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  box-shadow: 0px 15px 20px -13px #4894feaf;
}
.upload-avatar-btns button:nth-of-type(1) {
  color: var(--secondary-color);
}

.final-register-stage {
  width: 100%;
  height: 100%;
  padding: 0rem 2rem 2rem 2rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.5s ease-out;
  -webkit-transition: transform 0.5s ease-out;
  -moz-transition: transform 0.5s ease-out;
  -ms-transition: transform 0.5s ease-out;
  -o-transition: transform 0.5s ease-out;
  transform: translateX(200%);
  -webkit-transform: translateX(200%);
  -moz-transform: translateX(200%);
  -ms-transform: translateX(200%);
  -o-transform: translateX(200%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  content-visibility: auto;
}
.final-register-stage header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.final-register-stage-begin-animation {
  transform: translateX(0%);
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
}

.bold-text-label {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 0.875rem;
}
.expired-invitation {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  padding: 3rem 2rem;
}
.expired-invitation p {
  font-size: 1.1rem;
  max-width: 40ch;
}
.go-back-btn {
  background: #4895fe;
  width: calc(100% - 4rem);
  position: absolute;
  bottom: 10%;
  padding: 0.6rem 0rem;
  color: var(--very-light-white);
  font-weight: 500;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.icons8-back {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMjQiIGhlaWdodD0iMjQiCnZpZXdCb3g9IjAgMCAxNzIgMTcyIgpzdHlsZT0iIGZpbGw6IzAwMDAwMDsiPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0ibm9uemVybyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIHN0cm9rZS1saW5lY2FwPSJidXR0IiBzdHJva2UtbGluZWpvaW49Im1pdGVyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1kYXNoYXJyYXk9IiIgc3Ryb2tlLWRhc2hvZmZzZXQ9IjAiIGZvbnQtZmFtaWx5PSJub25lIiBmb250LXdlaWdodD0ibm9uZSIgZm9udC1zaXplPSJub25lIiB0ZXh0LWFuY2hvcj0ibm9uZSIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOiBub3JtYWwiPjxwYXRoIGQ9Ik0wLDE3MnYtMTcyaDE3MnYxNzJ6IiBmaWxsPSJub25lIj48L3BhdGg+PGcgZmlsbD0iI2ZmZmZmZiI+PHBhdGggZD0iTTkzLjE2NjY3LDQyLjQ5NjA5bC00My41MDM5MSw0My41MDM5MWw0My41MDM5MSw0My41MDM5bDEwLjc1LC0xMC43NWwtMzIuNzUzOTEsLTMyLjc1MzlsMzIuNzUzOTEsLTMyLjc1MzkxeiI+PC9wYXRoPjwvZz48L2c+PC9zdmc+')
    50% 50% no-repeat;
  background-size: 100%;
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
